<template>
<div class="">
    <b-img thumbnail fluid :src="absoluteUrl(category.image)" alt="category-img"></b-img>
    <b-button variant="light" class="mt-2" size="sm" @click.prevent="uploadModalShow = true">Upate Category Image</b-button>

    <b-modal v-model="uploadModalShow" hide-footer centered title="Update Category Image" title-class="font-18">
      <vue-dropzone id="categoryImageDrop" ref="categoryImageDrop" 
      :use-custom-slot="true" :maxFiles="1">
        <div class="needsclick m-0">
          <i class="h1 text-muted ri-upload-cloud-2-line"></i>
          <h4>Drop files here or click to upload.</h4>
          <span class="text-muted font-13">
            (Dimension should be 500 x 300 Max: 23kb)
          </span>
        </div>
      </vue-dropzone>
      <div class="d-flex mt-3 justify-content-end">
        <b-button class="mr-1"  @click.prevent="uploadModalShow = false">Close</b-button>
        <b-button variant="primary" @click.prevent="imageUpdate()" type="button">Update</b-button>
      </div>
    </b-modal>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';

export default {
    components: { 
        VueDropzone
    },
    data() {
        return {
            uploadModalShow: false,
        }
    },
    computed:{
         category(){
            return this.$store.state.categoryList.category
        }
    },
    methods:{
        imageUpdate(){
            if(!this.collectFiles().length){this.alertError("Category Image is Required"); return;}
            const formData = new FormData()
            formData.append('image_file', this.collectFiles()[0])
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/categories/${this.category.id}/update-image`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$store.commit("categoryList/UPDATE_CATEGORY", response.data.data)
                    this.resetData()
                }
            })
        },
        collectFiles(){
            var arrafile = []
            if(this.$refs.categoryImageDrop){
                arrafile = this.$refs.categoryImageDrop.getAcceptedFiles();
            }
            return arrafile
        },
        resetData(){
            this.$refs.categoryImageDrop.removeAllFiles();
            this.uploadModalShow = false;
        },
    }
}
</script>


<template>
  <div id="category-show-page">
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-2"> {{category.name}}</h4>
              <b-tabs nav-class="nav-tabs nav-bordered">
                <b-tab title="Overview" active>
                 <category-overview />
                </b-tab>
                <b-tab title="Products">
                  <category-products />
                </b-tab>
                <b-tab title="Description">
                  <category-description />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
  </div>
</template>
<script>

import IsLoading from "@/components/IsLoading.vue"
import CategoryOverview from "@/components/category/CategoryOverview.vue"
import CategoryProducts from "@/components/category/CategoryProducts.vue"
import CategoryDescription from "@/components/category/CategoryDescription.vue"

export default{
  components: {
    IsLoading,
    CategoryOverview,
    CategoryProducts,
    CategoryDescription
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    category(){
      return this.$store.state.categoryList.category
    }
  },
  created(){
    this.$store.dispatch("categoryList/fetchCategory", this.$route.params.categoryId)
    .then((response) => {
      this.isLoading = false
      if(!response.data.success){
        this.$router.push({path: '/error-404'})
      }
    })
  },
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-size:100%;
        background-repeat-y: no-repeat;
    }
    .faq-bg {
        background-color: #fff;
    }
}
</style>
